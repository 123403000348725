/// <reference path="../typings/globals/d3/index.d.ts" />
/// <reference path="../typings/globals/mithril/index.d.ts" />
/// <reference path="./typings/mithril/index.d.ts" />
//index.html上のsendFormの動作を上書きする
sendForm = () => {
    m.startComputation();
    loading(true);
    m.endComputation();
    var formElement = document.querySelector("form");
    var formData = new FormData(formElement);
    var request = new XMLHttpRequest();
    request.open("POST", "app/classifier", true);
    request.send(formData);
    request.onloadend = function (e) {
        var txtResponse = e.target.response;
        var data = JSON.parse(txtResponse);
        updateGraphs(data);
        //create or update graph
        console.log(data);
        m.startComputation();
        loading(false);
        m.endComputation();
    };
};
let probabilityLabel = Array.apply(null, { length: 11 }).map(Number.call, Number).reverse().map((n) => n / 10);
const SVG_WIDTH = 563;
const SVG_HEIGHT = 392;
const trainingGraphId = "trainingGraph";
const testGraphId = "testGraph";
let updateGraphs = (analysisResult) => {
    updateTrainingGraph(analysisResult[0]);
    updateTestGraph(analysisResult[1]);
};
let updateGraphField = (svgElem) => {
    svgElem.append("rect").attr("x", 0)
        .attr("y", 0)
        .attr("width", SVG_WIDTH)
        .attr("height", SVG_HEIGHT)
        .attr("fill", "#fbfbfb");
};
let updateGraphLine = (svgElem) => {
};
let updateTrainingGraph = (trainingData) => {
    let trainingSvgElem = d3.select("svg#" + trainingGraphId);
    trainingSvgElem.selectAll("*").remove();
    updateGraphField(trainingSvgElem);
    let trainingClass0 = "Class 0";
    updatePoints(trainingSvgElem, trainingData.series[trainingClass0], trainingClass0.replace(/ /g, "_"), "red", { max: trainingData.maxs, min: trainingData.mins });
    let trainingClass1 = "Class 1";
    updatePoints(trainingSvgElem, trainingData.series[trainingClass1], trainingClass1.replace(/ /g, "_"), "blue", { max: trainingData.maxs, min: trainingData.mins });
};
let updateTestGraph = (testData) => {
    let testSvgElem = d3.select("svg#" + testGraphId);
    testSvgElem.selectAll("*").remove();
    updateGraphField(testSvgElem);
    let a0p0 = "actual=0, pred=0";
    updatePoints(testSvgElem, testData.series[a0p0], a0p0.replace(/=/g, "").replace(/, /g, "_"), "red", { max: testData.maxs, min: testData.mins });
    let a0p1 = "actual=0, pred=1";
    updatePoints(testSvgElem, testData.series[a0p1], a0p1.replace(/=/g, "").replace(/, /g, "_"), "yellow", { max: testData.maxs, min: testData.mins });
    let a1p0 = "actual=1, pred=0";
    updatePoints(testSvgElem, testData.series[a1p0], a1p0.replace(/=/g, "").replace(/, /g, "_"), "green", { max: testData.maxs, min: testData.mins });
    let a1p1 = "actual=1, pred=1";
    updatePoints(testSvgElem, testData.series[a1p1], a1p1.replace(/=/g, "").replace(/, /g, "_"), "blue", { max: testData.maxs, min: testData.mins });
};
let updatePoints = (svgElem, points, className, circleColor, border) => {
    let width = Number.parseInt(svgElem.property("clientWidth"));
    let height = Number.parseInt(svgElem.property("clientHeight"));
    let pointElems = svgElem.selectAll("g." + className).data(points);
    let pointElemsEnter = pointElems.enter().append("g");
    pointElemsEnter.append("circle");
    pointElems.exit().remove();
    pointElems.attr({
        class: className,
        transform: point => {
            let actualRangeX = border.max[0] - border.min[0];
            let actualRangeY = border.max[1] - border.min[0];
            let dx = width / actualRangeX;
            let dy = height / actualRangeY;
            return "translate(" + (dx * (point[0] - border.min[0])) + "," + (height - dy * (point[1] - border.min[1])) + ")";
        }
    });
    pointElems.selectAll("circle").attr({
        r: 2,
        fill: circleColor
    });
};
let selectedFiles;
let loading = m.prop(false);
let fileInputOnChange = (e) => {
    selectedFiles = e.target.files;
};
let mainComponent = {
    controller: () => { },
    view: (ctrl) => {
        return m("div", { id: "wrapper" }, [
            m("h1", "Interprism Deep Learning"),
            m("div", { class: "central" }, m("div", { class: "inline", style: { "padding-right": "30px", "font-size": "1.5em", "font-weight": 300 } }, "Vector Data Classification"), m("div", { class: "inline" }, "二次元データの分類を学習させることができます。")),
            m("section", { id: "formWrap", class: "cfix" }, [
                m("form", { method: "post", action: "javascript:sendForm()", enctype: "multipart/form-data", class: "dropzone dz-clickable" }, [
                    m("label", { for: "inputFile" }, [
                        selectedFiles && selectedFiles.item(0) ? "＋CSVファイルを選択（" + selectedFiles.item(0).name + "）" : "＋CSVファイルを選択",
                        m("input", { id: "inputFile", name: "file", type: "file", onchange: fileInputOnChange, multiple: "multiple", style: { display: "none" } })
                    ]),
                    m("button", { type: "submit", name: "analyse", value: "Analyse" }, "Analyse")
                ]),
                loading() ? m("img", { src: "images/loading.gif" }) : ""
            ]),
            m("section", { id: "resultWrap" }, [
                m("ul", [
                    // m("li", { style: { "padding-top": "29px" } }, [
                    //     m("p", "Probability(class 0)"),
                    //     m("ul",
                    //         probabilityLabel.map(n => m("li", n.toFixed(2)))
                    //     ),
                    //     m("div", { class: "gage" })
                    // ]),
                    m("li", [
                        m("div", { class: "resultTitle" }, "Training Data"),
                        m("svg", { id: trainingGraphId, width: SVG_WIDTH + "px", height: SVG_HEIGHT + "px" }),
                        // m("img", { src: "images/graph_01.jpg", alt: "" }),
                        m("dl", { class: "resolutItem" }, [
                            m("dt", m("span", { class: "squareRed" })),
                            m("dd", "Class 0"),
                            m("dt", m("span", { class: "circleBlue" })),
                            m("dd", "Class 1"),
                        ])
                    ]),
                    m("li", [
                        m("div", { class: "resultTitle" }, "Test Data"),
                        m("svg", { id: testGraphId, width: SVG_WIDTH + "px", height: SVG_HEIGHT + "px" }),
                        // m("img", { src: "images/graph_02.jpg", alt: "" }),
                        m("dl", { class: "resolutItem" }, [
                            m("dt", m("span", { class: "squareRed" })),
                            m("dd", "actual=0 , pred=0"),
                            m("dt", m("span", { class: "diamondYellow" })),
                            m("dd", "actual=0 , pred=1"),
                            m("dt", m("span", { class: "triangleGreen" })),
                            m("dd", "actual=1 , pred=0"),
                            m("dt", m("span", { class: "circleBlue" })),
                            m("dd", "actual=1 , pred=1")
                        ])
                    ])
                ])
            ]),
            m("div", { class: "central", style: { "padding-top": "30px" } }, m("div", {
                style: {
                    width: "800px",
                    margin: "20px auto",
                    border: "solid 1px",
                    padding: "10px"
                }
            }, [
                m("h2", "Sample Data"),
                downloadData.map(d => {
                    return m("div", { class: "inline", style: { margin: "5px 20px" } }, m("a", { href: d.src }, d.name));
                })
            ]))
        ]);
    }
};
m.mount(document.body, m.component(mainComponent));
